<template>
	<div>
		<el-card>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="基本设置" name="基本设置">
					<el-form class="left ml15 mt15 w350" :rules="otherRules" ref="otherForm" :model="otherData" label-width="120px">
						<el-form-item label="站点名称" prop="title">
							<el-input v-model="otherData.title" placeholder="请输入站点名称"></el-input>
						</el-form-item>
						<el-form-item label="站点关键词" prop="subtitle">
							<el-input v-model="otherData.subtitle" placeholder="请输入站点关键词"></el-input>
						</el-form-item>
						<el-form-item label="站点描述" prop="desciption">
							<el-input type="textarea" :rows="5" v-model="otherData.desciption" placeholder="请输入站点描述"></el-input>
						</el-form-item>
						<el-form-item label="公司地址" prop="address">
							<el-input v-model="otherData.address" placeholder="请输入公司地址"></el-input>
						</el-form-item>
						<el-form-item label="备案类型" prop="icp_type">
							<el-radio-group v-model="otherData.icp_type">
								<el-radio :label="'1'">ICP备案/许可证号</el-radio>
								<el-radio :label="'2'">网站联网备案号</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="备案号" prop="icp">
							<el-input v-model="otherData.icp" maxlength="50" show-word-limit placeholder="请输入备案号"></el-input>
						</el-form-item>
						<el-form-item label="合作电话" prop="tel">
							<el-input v-model="otherData.tel" placeholder="请输入合作电话"></el-input>
						</el-form-item>
						<el-form-item label="客服QQ" prop="qq">
							<el-input v-model="otherData.qq" placeholder="请输入客服QQ"></el-input>
						</el-form-item>
						<el-form-item label="统计代码" prop="statisticalcode">
							<el-input type="textarea" :rows="6" v-model="otherData.statisticalcode" placeholder="请输入统计代码"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="otherUpload">提交</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="小程序设置" name="小程序设置">
					<el-form class="left ml15 mt15 w350" :rules="appletRules" ref="appletForm" :model="appletData" label-width="100px">
						<el-form-item label="AppID" prop="AppID">
							<el-input v-model="appletData.AppID" placeholder="请输入AppID"></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">请输入小程序AppID,填写错误会影响小程序的正常使用,谨慎修改</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="AppSecret" prop="AppSecret">
							<el-input v-model="appletData.AppSecret" placeholder="请输入AppSecret"></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">请输入小程序AppSecret,填写错误会影响小程序的正常使用,谨慎修改</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="小程序名" prop="appletName">
							<el-input v-model="appletData.appletName" maxlength="10" show-word-limit placeholder="请输入小程序名"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="appletSub">提交</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="公众号设置" name="公众号设置">
					<el-form class="left ml15 mt15 w350" :rules="publicRules" ref="publicForm" :model="publicData" label-width="100px">
						<el-form-item label="AppID" prop="AppID">
							<el-input v-model="publicData.AppID" placeholder="请输入AppID"></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">请输入公众号AppID,填写错误会影响小程序的正常使用,谨慎修改</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="AppSecret" prop="AppSecret">
							<el-input v-model="publicData.AppSecret" placeholder="请输入AppSecret"></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">请输入公众号AppSecret,填写错误会影响小程序的正常使用,谨慎修改</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="百度key" prop="baiduKey">
							<el-input v-model="publicData.baiduKey" placeholder="请输入百度key"></el-input>
						</el-form-item>
						<el-form-item label="腾讯key" prop="tengxunKey">
							<el-input v-model="publicData.tengxunKey" placeholder="请输入腾讯key"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="publicSub">提交</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="APP设置" name="APP设置">
					<el-form class="left ml15 w350" :rules="appRules" ref="appForm" :model="appData" label-width="100px">
						<el-form-item label="AppID" prop="AppID">
							<el-input v-model="appData.AppID" placeholder="请输入微信开放平台移动应用AppId"></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">请输入微信开放平台申请移动应用的AppID,输入错误会影响APP的正常使用,谨慎修改</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="AppSecret" prop="AppSecret">
							<el-input v-model="appData.AppSecret" placeholder="请输入微信开放平台移动应用AppSecret"></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">请输入微信开放平台申请移动应用的AppSecret,输入错误会影响APP的正常使用,谨慎修改</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="AppName" prop="AppName">
							<el-input v-model="appData.AppName" placeholder="请输入App名称" maxlength="10" show-word-limit></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">用于APP登录页面展示</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="App Logo" prop="AppLogo">
							<div style="display:flex">
								<div class="previewUpload">
									<div v-show="appData.AppLogo.length>0" class="previewItem" v-for="(item,index) in appData.AppLogo" :key="index">
										<img :src="item.img_path" alt="" @click="openUploadDialog((1-appData.AppLogo.length),'cover')">
										<i class="el-icon-close delIcon" @click="delCoverItem(index)"></i>
									</div>
									<div class="previewItems" v-if="appData.AppLogo.length==0" @click="openUploadDialog((1-appData.AppLogo.length),'cover')"><i class="el-icon-plus previewadd"></i></div>
								</div>
								<el-tooltip placement="right" class="tooltips">
									<div slot="content">用于APP登录页面展示</div>
									<i class="el-icon-question"></i>
								</el-tooltip>
							</div>
							<Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
						</el-form-item>
						<el-form-item label="用户隐私协议" prop="AppYsxy">
							<quill-editor v-model="appData.AppYsxy"></quill-editor>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="subApp">提交</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="支付设置" name="支付设置">
					<el-form class="left ml15 w350" :rules="payRules" ref="payForm" :model="payData" label-width="80px">
						<el-form-item label="商户号" prop="mchid">
							<el-input v-model="payData.mchid" placeholder="请输入商户号"></el-input>
						</el-form-item>
						<el-form-item label="支付密钥" prop="pay_key">
							<el-input v-model="payData.pay_key" placeholder="请输入支付密钥"></el-input>
						</el-form-item>
						<el-form-item label="cert_path" prop="cert_path">
							<div style="display:flex">
								<el-upload class="upload-demo" ref="upload_cert" action="https://jsonplaceholder.typicode.com/posts/" accept=".pem" :before-remove="()=>false" :on-change="handleChangeCert" :file-list="filelist_cert" :auto-upload="false">
									<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
								</el-upload>
								<el-tooltip placement="right" class="tooltips">
									<div slot="content">请上传pem格式的文件</div>
									<i class="el-icon-question"></i>
								</el-tooltip>
							</div>
						</el-form-item>
						<el-form-item label="key_path" prop="key_path">
							<div style="display:flex">
								<el-upload class="upload-demo" ref="upload_key" action="https://jsonplaceholder.typicode.com/posts/" accept=".pem" :before-remove="()=>false" :on-change="handleChangeKey" :file-list="filelist_key" :auto-upload="false">
									<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
								</el-upload>
								<el-tooltip placement="right" class="tooltips">
									<div slot="content">请上传pem格式的文件</div>
									<i class="el-icon-question"></i>
								</el-tooltip>
							</div>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="subPay">提交</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="上传设置" name="上传设置">
					<el-form class="left ml15 mt15 w350" :rules="uploadRules" ref="uploadForm" :model="uploadData" label-width="120px">
						<el-form-item label="请选择上传类型" prop="uploadType">
							<el-input value="阿里云OSS" disabled></el-input>
						</el-form-item>
						<el-form-item label="存储区" prop="zone">
							<el-select v-model="uploadData.zone" placeholder="请选择">
								<el-option v-for="(item,index) in commonList.oss_zone" :key="index" :label="item.name" :value="index">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否内网" prop="internal">
							<el-radio-group v-model="uploadData.internal">
								<el-radio :label="'0'">否</el-radio>
								<el-radio :label="'1'">是</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="AccessKeyId" prop="access_id">
							<el-input v-model="uploadData.access_id" placeholder="请输入AccessKeyId"></el-input>
						</el-form-item>
						<el-form-item label="AccessKeySecret" prop="access_secret">
							<el-input v-model="uploadData.access_secret" placeholder="请输入AccessKeySecret"></el-input>
						</el-form-item>
						<el-form-item label="bucket" prop="bucket">
							<el-input v-model="uploadData.bucket" placeholder="请输入仓库名称"></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">代表仓库顶层目录名称,请以英文字母命名,前后不用加'/',例如:mingpian_image</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item label="自定义域名" prop="cdn_host">
							<el-input v-model="uploadData.cdn_host" placeholder="请输入自定义域名"></el-input>
							<el-tooltip placement="right" class="tooltips">
								<div slot="content">自定义域名前面不用加https://</div>
								<i class="el-icon-question"></i>
							</el-tooltip>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="subUpload">提交</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<!-- 费用设置 -->
				<el-tab-pane label="费用设置" name="费用设置">
					<el-row class="left ml30">
						<el-col :span="24">
							<el-form class="left ml15 mt15 w350" :rules="feiyRules" ref="feiyForm" :model="feiyData" label-width="220px">
								<el-form-item label="技师前一个月分佣比例(%)" prop="before_one_month_fybl">
									<el-input type="number" v-model="feiyData.before_one_month_fybl" placeholder="请输入技师前一个月分佣比例"></el-input>
									<el-tooltip placement="right" class="tooltips">
										<div slot="content">技师前一个月服务订单最低分佣比例</div>
										<i class="el-icon-question"></i>
									</el-tooltip>
								</el-form-item>
								<el-form-item label="最低提现额度" prop="min_txje">
									<el-input type="number" v-model="feiyData.min_txje" placeholder="请输入最低提现额度"></el-input>
									<el-tooltip placement="right" class="tooltips">
										<div slot="content">技师/代理/经理/门店最低可提现额度</div>
										<i class="el-icon-question"></i>
									</el-tooltip>
								</el-form-item>
								<!--<el-form-item label="渠道扣费(%)" prop="qudao_bl">
									<el-input type="number" v-model="feiyData.qudao_bl" placeholder="请输入渠道扣费百分比"></el-input>
									<el-tooltip placement="right" class="tooltips">
										<div slot="content">技师/经理/门店渠道扣费分佣均摊百分比</div>
										<i class="el-icon-question"></i>
									</el-tooltip>
								</el-form-item>-->
								<el-form-item label="服务费扣费(%)" prop="fwf_bl">
									<el-input type="number" v-model="feiyData.fwf_bl" placeholder="请输入服务费扣费百分比" max="6" @input="numberChange(arguments[0], 6, 'fwf_bl')" @change="numberChange(arguments[0], 6, 'fwf_bl')"></el-input>
									<el-tooltip placement="right" class="tooltips">
										<div slot="content">服务费扣费最大不能超过6%</div>
										<i class="el-icon-question"></i>
									</el-tooltip>
								</el-form-item>
								<el-form-item label="车费提现手术费(%)" prop="tixian_bl">
									<el-input type="number" v-model="feiyData.tixian_bl" placeholder="请输入提现手术费"></el-input>
								</el-form-item>
								<el-form-item label="门店分成比例(%)" prop="store_bl">
									<el-input type="number" v-model="feiyData.store_bl" placeholder="请输入门店费用比例" max="50" @input="numberChange(arguments[0], 50, 'store_bl')" @change="numberChange(arguments[0], 50, 'store_bl')"></el-input>
								</el-form-item>
								<el-form-item label="门店技师分成比例(%)" prop="store_jishi_bl">
									<el-input type="number" v-model="feiyData.store_jishi_bl" placeholder="请输入门店技师分成比例" max="50" @input="numberChange(arguments[0], 50, 'store_jishi_bl')" @change="numberChange(arguments[0], 50, 'store_jishi_bl')"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="feiySub">提交</el-button>
								</el-form-item>
							</el-form>
						</el-col>
					</el-row>
				</el-tab-pane>
				<!-- 车费设置 -->
				<el-tab-pane label="车费设置" name="车费设置">
					<el-row class="tip">
						<el-col :span="24">
							<div class="custom-item">
								出租出行
							</div>
							<el-button type="warning" plain size="small" @click="addFareSetting">添加</el-button>
						</el-col>
					</el-row>
					<div class="fare-setting" v-for="(item,index) in fareData" :key="index">
						<div class="items">
							<div class="title">出行时间:</div>
							<el-time-picker
							    v-model="item.start_time"
									value-format="HH:mm"
							    :picker-options="{
							      selectableRange: '00:00:00 - ' + item.end_time + ':00'
							    }"
							    placeholder="选择时间" format="HH:mm"></el-time-picker>
									-
							<el-time-picker
							    v-model="item.end_time"
									value-format="HH:mm"
							    :picker-options="{
							      selectableRange: item.start_time + ':00 - 23:59:00'
							    }"
							    placeholder="选择时间" format="HH:mm"></el-time-picker>
									
						<!-- 	<el-time-select v-model="item.start_time" :picker-options="{
				                  start: '00:00',
				                  step: '00:01',
				                  end: '23:59',
				                  maxTime: item.end_time
				                }" placeholder="选择时间">
							</el-time-select> -
							<el-time-select v-model="item.end_time" :picker-options="{
			                  start: '00:00',
			                  step: '00:01',
			                  end: '23:59',
			                  minTime: item.start_time
                					}" placeholder="选择时间">
							</el-time-select> -->
						</div>
						<div class="items">
							<el-input v-model="item.mianfei_distance" placeholder="" style="width: 100px;"></el-input>&nbsp;&nbsp;公里以内免费,
							起步距离:<el-input v-model="item.qibu_distance" style="width: 100px;"></el-input>&nbsp;&nbsp;公里
						</div>
						<div class="items">
							<div class="title">起步价:</div>
							<el-input v-model="item.qibu_price" placeholder="请输入起步价"></el-input>&nbsp;&nbsp;元
						</div>
						<div class="items">
							<div class="title">里程计价:</div>
							<el-input v-model="item.lc_price" placeholder="请输入里程计价"></el-input>&nbsp;&nbsp;元/公里
						</div>
						<el-button type="danger" v-if="index>0" size="mini" icon="el-icon-delete" @click="delFara(index, item)"></el-button>
					</div>
					<div class="left">
						<el-button type="primary" @click="subFare">保存</el-button>
					</div>
				</el-tab-pane>
				<!-- 出行设置 -->
				<el-tab-pane label="出行设置" name="出行设置">
					<el-row class="left ml30">
						<el-col :span="24">
							<el-form :rules="travlRules" ref="travlForm" :model="travlData" label-width="220px">
								<el-form-item label="是否开启公交/地铁出行方式：" prop="is_open_travl">
									<template>
										<el-radio v-model="travlData.is_open_travl" label="0">开启</el-radio>
										<el-radio v-model="travlData.is_open_travl" label="1">关闭</el-radio>
									</template>
								</el-form-item>
								<el-form-item label="可出行时间：" prop="travl_time">
									<el-time-picker
									    v-model="travlData.travl_start_time"
											value-format="HH:mm"
									    :picker-options="{
									      selectableRange: '00:00:00 - ' + travlData.travl_end_time + ':00'
									    }"
									    placeholder="选择时间" format="HH:mm"></el-time-picker>
											-
									<el-time-picker
									    v-model="travlData.travl_end_time"
											value-format="HH:mm"
									    :picker-options="{
									      selectableRange: travlData.travl_start_time + ':00 - 23:59:00'
									    }"
									    placeholder="选择时间" format="HH:mm"></el-time-picker>
									<!-- <el-time-select v-model="travlData.travl_start_time" :picker-options="{
									    start: '00:00',
									    step: '00:01',
									    end: '23:59',
									    maxTime: travlData.travl_end_time
									  }" placeholder="选择时间">
									</el-time-select> -
									<el-time-select v-model="travlData.travl_end_time" :picker-options="{
									    start: '00:00',
									    step: '00:01',
									    end: '23:59',
									    minTime: travlData.travl_start_time
									  }" placeholder="选择时间">
									</el-time-select> -->
								</el-form-item>
								<el-form-item>
									<el-button type="primary" @click="travlSub">提交</el-button>
								</el-form-item>
							</el-form>
						</el-col>
					</el-row>
				</el-tab-pane>
			</el-tabs>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import axios from 'axios'
	import Upload from '@/components/Upload/Upload.vue'
	export default {
		components: {
			Upload
		},
		data() {
			// 验证可出行时间
			var checktravlTime = (rule, value, cb) => {
				if(this.travlData.travl_start_time != null) {
					return cb();
				} else {
					cb(new Error("请选择开始时间"));
				}
				if(this.travlData.travl_end_time != null) {
					return cb();
				} else {
					cb(new Error("请选择结束时间"));
				}
			};
			const before_one_month_fybl = (rule, value, callback) => {
				let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if(!value || !ret.test(value)) callback(new Error('请输入正确的金额，最多保留两位小数'))
				callback()
			}
			const min_txje = (rule, value, callback) => {
				let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if(!value || !ret.test(value)) callback(new Error('请输入正确的金额，最多保留两位小数'))
				callback()
			}
			const qudao_bl = (rule, value, callback) => {
				let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if(!value || !ret.test(value)) callback(new Error('请输入正确的金额，最多保留两位小数'))
				callback()
			}
			const fwf_bl = (rule, value, callback) => {
				let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if(!value || !ret.test(value)) callback(new Error('请输入正确的金额，最多保留两位小数'))
				callback()
			}
			const tixian_bl = (rule, value, callback) => {
				let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if(!value || !ret.test(value)) callback(new Error('请输入正确的金额，最多保留两位小数'))
				callback()
			}
			const store_bl = (rule, value, callback) => {
				let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if(!value || !ret.test(value)) callback(new Error('请输入正确的金额，最多保留两位小数'))
				callback()
			}
			const store_jishi_bl = (rule, value, callback) => {
				let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
				if(!value || !ret.test(value)) callback(new Error('请输入正确的金额，最多保留两位小数'))
				callback()
			}
			const AppLogo = (rule, value, callback) => {
				if(this.appData.AppLogo.length === 0) {
					callback(new Error('请选择App Logo'))
				}
				callback()
			}
			const cert_path = (rule, value, callback) => {
				if(JSON.stringify(this.payData.cert_path) === "{}") {
					callback(new Error('请上传cert_path'))
				}
				callback()
			}
			const key_path = (rule, value, callback) => {
				if(JSON.stringify(this.payData.key_path) === "{}") {
					callback(new Error('请上传key_path'))
				}
				callback()
			}
			return {
				filelist_cert: [],
				filelist_key: [],
				chooseImgCount: 0,
				pictureType: '',
				uploadVisible: false,
				activeName: '基本设置',
				commonList: {},
				otherData: {
					title: '',
					subtitle: '',
					desciption: '',
					address: '',
					icp_type: '1',
					icp: '',
					tel: '',
					qq: '',
					statisticalcode: '',
				},
				appletData: {
					AppID: '',
					AppSecret: '',
					appletName: ''
				},
				publicData: {
					AppID: '',
					AppSecret: '',
					baiduKey: '',
					tengxunKey: '',
				},
				appData: {
					AppID: '',
					AppSecret: '',
					AppName: '',
					AppLogo: [],
					AppYsxy: ''
				},
				payData: {
					mchid: '',
					pay_key: '',
					cert_path: {},
					key_path: {}
				},
				uploadData: {
					zone: '',
					internal: '0',
					access_id: '',
					access_secret: '',
					bucket: '',
					cdn_host: '',
				},
				feiyData: {
					before_one_month_fybl: '',
					min_txje: '',
					qudao_bl: '',
					fwf_bl: '',
					tixian_bl: '',
					store_bl: '',
					store_jishi_bl: ''
				},
				fareData: [{
					start_time: '',
					end_time: '',
					mianfei_distance: '',
					qibu_distance: '',
					qibu_price: '',
					lc_price: ''
				}],
				travlData: {
					is_open_travl: '0',
					travl_start_time: '09:00',
					travl_end_time: '23:00',
				},
				otherRules: {
					title: [{
						required: true,
						message: '请输入站点名称',
						trigger: 'blur'
					}],
					subtitle: [{
						required: true,
						message: '请输入站点关键词',
						trigger: 'blur'
					}],
					desciption: [{
						required: true,
						message: '请输入站点描述',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入公司地址',
						trigger: 'blur'
					}],
					icp_type: [{
						required: true,
						message: '请输入备案类型',
						trigger: 'blur'
					}],
					icp: [{
						required: true,
						message: '请输入备案号',
						trigger: 'blur'
					}],
					tel: [{
						required: true,
						message: '请输入合作电话',
						trigger: 'blur'
					}],
					qq: [{
						required: true,
						message: '请输入客服QQ',
						trigger: 'blur'
					}],
					statisticalcode: [{
						required: true,
						message: '请输入统计代码',
						trigger: 'blur'
					}],
				},
				appletRules: {
					AppID: [{
						required: true,
						message: '请输入AppID',
						trigger: 'blur'
					}],
					AppSecret: [{
						required: true,
						message: '请输入AppSecret',
						trigger: 'blur'
					}],
					appletName: [{
						required: true,
						message: '请输入小程序名',
						trigger: 'blur'
					}],
				},
				publicRules: {
					AppID: [{
						required: true,
						message: '请输入AppID',
						trigger: 'blur'
					}],
					AppSecret: [{
						required: true,
						message: '请输入AppSecret',
						trigger: 'blur'
					}],
				},
				appRules: {
					AppID: [{
						required: true,
						message: '请输入微信开放平台移动应用AppID',
						trigger: 'blur'
					}],
					AppSecret: [{
						required: true,
						message: '请输入微信开放平台移动应用AppSecre',
						trigger: 'blur'
					}],
					AppName: [{
						required: true,
						message: '请输入App名称',
						trigger: 'blur'
					}],
					AppLogo: [{
						validator: AppLogo,
						trigger: 'blur'
					}],
					AppYsxy: [{
						required: true,
						message: '请输入用户隐私协议',
						trigger: 'blur'
					}]
				},
				payRules: {
					mchid: [{
						required: true,
						message: '请输入商户号',
						trigger: 'blur'
					}],
					pay_key: [{
						required: true,
						message: '请输入支付密钥',
						trigger: 'blur'
					}],
					cert_path: [{
						validator: cert_path,
						trigger: 'blur'
					}],
					key_path: [{
						validator: key_path,
						trigger: 'blur'
					}],
				},
				uploadRules: {
					zone: [{
						required: true,
						message: '请选择存储区',
						trigger: 'blur'
					}],
					internal: [{
						required: true,
						message: '请选择是否内网',
						trigger: 'blur'
					}],
					access_id: [{
						required: true,
						message: '请输入AccessKeyId',
						trigger: 'blur'
					}],
					access_secret: [{
						required: true,
						message: '请输入AccessKeySecret',
						trigger: 'blur'
					}],
					bucket: [{
						required: true,
						message: '请输入仓库名称',
						trigger: 'blur'
					}],
					cdn_host: [{
						required: true,
						message: '请输入自定义域名',
						trigger: 'blur'
					}],
				},
				feiyRules: {
					before_one_month_fybl: [{
							required: true,
							message: '请输入技师前一个月分佣比例',
							trigger: 'blur'
						},
						{
							validator: before_one_month_fybl,
							trigger: 'blur'
						}
					],
					min_txje: [{
							required: true,
							message: '请输入最低提现额度',
							trigger: 'blur'
						},
						{
							validator: min_txje,
							trigger: 'blur'
						}
					],
					qudao_bl: [{
							required: true,
							message: '请输入渠道扣费百分比',
							trigger: 'blur'
						},
						{
							validator: qudao_bl,
							trigger: 'blur'
						}
					],
					fwf_bl: [{
							required: true,
							message: '请输入服务费扣费百分比',
							trigger: 'blur'
						},
						{
							validator: fwf_bl,
							trigger: 'blur'
						}
					],
				},
				travlRules: {
					is_open_travl: [{
						required: true,
						message: '请选择是否开启公交/地铁出行方式',
						trigger: 'blur'
					}],
					travl_time: [{
						required: true,
						validator: checktravlTime,
						trigger: "blur"
					}, ]
				},
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getSiteConfig();
				//获取子项配置
				this.getSubSiteConfig();
				//车费记录
				this.getCarfeeList();
			}
		},
		methods: {
			getSiteConfig() {
				var url = 'system/init_data';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.otherData = res.site_config;
						//费用数据
						that.feiyData = {
							before_one_month_fybl: res.site_config.before_one_month_fybl,
							min_txje: res.site_config.min_txje,
							qudao_bl: res.site_config.qudao_bl,
							fwf_bl: res.site_config.fwf_bl,
							tixian_bl: res.site_config.tixian_bl,
							store_bl: res.site_config.store_bl,
							store_jishi_bl: res.site_config.store_jishi_bl
						};
						//出行数据
						that.travlData = {
							is_open_travl: res.site_config.is_open_travl,
							travl_start_time: res.site_config.travl_start_time,
							travl_end_time: res.site_config.travl_end_time,
						};
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getSubSiteConfig() {
				var url = 'system/sub_site_config';
				let params = {
					sub_col: ["xcx", "wx", "oss", "wxpay", "app"]
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.commonList = res.common_list;
						that.appletData = res.list.xcx_config;
						that.publicData = res.list.wx_config;
						that.uploadData = res.list.oss_config;
						//支付设置赋值
						that.payData = res.list.wxpay_config;
						if(that.payData.cert_path) {
							that.filelist_cert = [{
								name: that.payData.cert_path
							}];
						}
						if(that.payData.key_path) {
							that.filelist_key = [{
								name: that.payData.key_path
							}];
						}
						//app设置
						that.appData = res.list.app_config;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getCarfeeList() {
				var url = 'system/carfee_list';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						if(res.list.length > 0) {
							that.fareData = res.list;
						}
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			handleChangeCert(file, fileList) {
				let idx = file.name.indexOf('.')
				let fileType = file.name.slice(idx + 1)
				if(idx == -1 || fileType != 'pem') {
					this.$message.error('请选择pem文件')
					this.payData.cert_path = {}
					this.filelist_cert = []
					return
				}
				if(fileList && fileList.length >= 2) {
					fileList.shift();
				}
				this.payData.cert_path = file;
			},
			handleChangeKey(file, fileList) {
				let idx = file.name.indexOf('.')
				let fileType = file.name.slice(idx + 1)
				if(idx == -1 || fileType != 'pem') {
					this.$message.error('请选择pem文件')
					this.payData.key_path = {}
					this.filelist_key = []
					return
				}
				if(fileList && fileList.length >= 2) {
					fileList.shift();
				}
				this.payData.key_path = file
			},
			openUploadDialog(count, pictureType) {
				this.pictureType = pictureType
				this.chooseImgCount = count
				this.uploadVisible = true
			},
			closeDelDialog() {
				this.uploadVisible = false
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			receiveRes(chooseList, pictureType) {
				if(pictureType === 'cover') {
					this.appData.AppLogo = this.appData.AppLogo.concat(chooseList);
				}
				this.uploadVisible = false;
			},
			//基本设置
			otherUpload() {
				this.$refs.otherForm.validate(valid => {
					if(!valid) return;
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'system/main';
						let params = that.otherData;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.$message.success('操作成功');
								that.getSiteConfig();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				})
			},
			updateSubConfig(param_data) {
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'system/updateSubConfig';
					let params = param_data;
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('操作成功');
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}, 300);
			},
			// 小程序设置
			appletSub() {
				this.$refs.appletForm.validate(valid => {
					if(!valid) return
					that.appletData.parent_id = "xcx";
					that.updateSubConfig(that.appletData);
				})
			},
			// 公众号设置
			publicSub() {
				this.$refs.publicForm.validate(valid => {
					if(!valid) return
					that.publicData.parent_id = "wx";
					that.updateSubConfig(that.publicData);
				})
			},
			// APP设置
			subApp() {
				this.$refs.appForm.validate(valid => {
					if(!valid) return
					that.appData.parent_id = "app";
					that.appData.LogoImgs = JSON.stringify(that.appData.AppLogo);
					that.updateSubConfig(that.appData);
				})
			},
			// 支付设置
			subPay() {
				this.$refs.payForm.validate(valid => {
					if(!valid) return;
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'system/wxpay';
						var formData = new FormData();
						formData.append("mchid", that.payData.mchid);
						formData.append("pay_key", that.payData.pay_key);
						if(that.payData.cert_path.raw) {
							formData.append("cert_file[]", that.payData.cert_path.raw, that.payData.cert_path.name);
						}
						if(that.payData.key_path.raw) {
							formData.append("key_file[]", that.payData.key_path.raw, that.payData.key_path.name);
						}
						axios({
							url: url,
							headers: {
								"Content-Type": "multipart/form-data",
							},
							method: "post",
							data: formData,
						}).then(res => {
							if(res.status) {
								that.$message.success('操作成功');
							} else {
								that.$message.error(res.msg);
							}
						}).catch(error => {
							that.$message.error('网络错误');
						})
					}, 300);
				})
			},
			//费用设置
			feiySub() {
				this.$refs.feiyForm.validate(valid => {
					if(!valid) return;
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'system/main';
						let params = that.feiyData;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.$message.success('操作成功');
								that.getSiteConfig();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 300);
				})
			},
			// 上传设置
			subUpload() {
				this.$refs.uploadForm.validate(valid => {
					if(!valid) return
					that.uploadData.parent_id = "oss";
					that.updateSubConfig(that.uploadData);
				})
			},
			//添加车费
			addFareSetting() {
				this.fareData.push({
					start_time: '',
					end_time: '',
					mianfei_distance: '',
					qibu_distance: '',
					qibu_price: '',
					lc_price: ''
				});
			},
			//删除车费item
			delFara(i) {
				this.fareData.splice(i, 1);
			},
			//保存
			subFare() {
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'system/carfee_setting';
					let params = {
						carfee_json: JSON.stringify(this.fareData)
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('操作成功');
							that.getCarfeeList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}, 300);
			},
			//出行提交
			travlSub() {
				this.$refs.travlForm.validate(valid => {
					if(!valid) return
					var url = 'system/main';
					let params = that.travlData;
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('操作成功');
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				})
			},
			numberChange(val, maxNum, name) {
				if(isNaN(Number(val))){
					this.$message.error("请输入数字");
					this.feiyData[name] = 0;
					return;
				}
				//转换数字类型
				this.feiyData[name] = Number(val)
				//重新渲染
				this.$nextTick(() => {
					//比较输入的值和最大值，返回小的
					let num = Math.min(Number(val), maxNum)
					//输入负值的情况下， = 0（可根据实际需求更该）
					if(num < 0) {
						this.feiyData[name] = 0
					} else {
						//反之
						this.feiyData[name] = num
					}
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	@import './Sys_settings.scss';
</style>